import React from 'react'

import useVerifyRole from '../../hooks/verifyRole'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import Button from '../../components/Button'
import SEO from '../../components/SEO'

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])

  if (loading || !hasRole) {
    return null
  }

  return (
    <>
      <SEO title="Administrasjon" />
      <Hero title="Administrasjon" type={['center', 'small']} />

      <Section size="small">
        <div
          style={{ textAlign: 'center', minHeight: 'calc(100vh - 48.3rem)' }}
        >
          <Button to="/admin/brukere" primary arrow spaceless>
            Brukere
          </Button>
          <br /><Button to="/admin/gavekort" primary arrow spaceless>
            Gavekort
          </Button>
          <br />
          <Button to="/admin/fakturaer" primary arrow spaceless>
            Fakturaer
          </Button>
          <br />
          <Button to="/admin/rapporter" primary arrow spaceless>
            Rapporter
          </Button>
          <br/>
          <Button to="/admin/statistikk" primary arrow>
            Statistikk
          </Button>
        </div>
      </Section>
    </>
  )
}
